import { useEffect } from "preact/hooks";

function Slider() {
    useEffect(() => {
        // Initialize Owl Carousel after component mounts
        $(document).ready(function () {
            $(".owl-carousel").owlCarousel({
                loop: true,
                margin: 10,
                // nav: true,
                items: 1, // Show one item per slide
                autoplay: true,
                autoplayTimeout: 3000, // Time between slides (3 seconds)
                autoplaySpeed: 1000, // Transition speed (1 second)
                smartSpeed: 1500, // Smooth transition effect
                lazyLoad: true,  // Load images only when they are in view
                autoplayHoverPause: true, // Pause on hover
                animateOut: "fadeOut", // Add fade out effect (optional)
            });
        });
    }, []);

    return (
        <div class="mx-auto w-9/12 ">
            <div className="owl-carousel">
                <div className="item">
                    <div
                        className="slide"
                        style={{
                            background:
                                "url(carousel1.jpg)",
                            height: "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div>
                <div className="item">
                    <div
                        className="slide"
                        style={{
                            background:
                                "url(carousel2.jpg)",
                            height: "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div>
                {/* <div className="item">
                    <div
                        className="slide"
                        style={{
                            background:
                                "url(carousel3.jpg)",
                            height: "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Slider;

